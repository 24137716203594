

function WeixinQrcodePage(){
    return (
        <div className="flex justify-center py-10 sm:py-32">
            <img className="w-3/4 sm:w-1/4" src="http://files.revome.cn/public/a2d531015d49db700ba4eb4e029b7063_保存图片添加管家.jpg">
            </img>
        </div>
        )
}

export default WeixinQrcodePage;