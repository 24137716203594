

function QQPage(){
    return (
        <div className="flex justify-center py-10 sm:py-32">
        <img className="w-full sm:w-1/2" src="http://files.revome.cn/public/96c8004b7e86e5d8102fca7e26d95e92_IMG_3818.JPG">
        </img>
    </div>
    )
}
export default QQPage;