
import IndexPage from "./IndexPage";
import FeaturesView from "./FeaturesView";
import DeclarationView from "./DeclarationView"
import RoomsView from "./RommsView";


function HomeView() {
    return (
        <div>
            <IndexPage />
            <RoomsView />
            <FeaturesView />
            <DeclarationView />
        </div>
    )
}

export default HomeView;