import { useState } from "react";
import { Outlet } from "react-router-dom";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

function MainView() {
    return (
        <main className="flex flex-col w-full sm:max-w-[1440px] items-center justify-center">
            <NavigationBar />
            <div className="h-20"></div>
            <Outlet />
            <Footer />
        </main>
    )
}

export default MainView;